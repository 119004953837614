/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import theme from '@theme/theme';
import Banner from '@components/banner';
import Footer from './footer';

const Layout: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <Banner />
      <main>{children}</main>
      <Footer />
    </>
  </ThemeProvider>
);
const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-family: 'roboto';
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.text};
  }
  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

export default Layout;
