import * as React from 'react';
import styled from 'styled-components';

const Footer: React.FC = () => (
  <Wrapper>
    <ul>
      <Icon>
        <a
          href="https://twitter.com/rom8nou"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon />
        </a>
      </Icon>
      <Icon>
        <a
          href="https://dribbble.com/rguillaume"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DribbbleIcon />
        </a>
      </Icon>
      <Icon>
        <a
          href="mailto:rguillaume.home@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MailIcon />
        </a>
      </Icon>
      <Icon>
        <a
          href="https://www.linkedin.com/in/r-guillaume/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedIcon />
        </a>
      </Icon>
    </ul>
  </Wrapper>
);

const TwitterIcon = () => (
  <svg width="24" height="20" viewBox="0 0 24 20">
    <StyledPath
      d="M659,32.309 C658.117,32.701 657.168,32.965 656.172,33.084 C657.189,32.475 657.97,31.51 658.337,30.36 C657.386,30.924 656.332,31.334 655.21,31.555 C654.313,30.598 653.032,30 651.616,30 C648.437,30 646.101,32.966 646.819,36.045 C642.728,35.84 639.1,33.88 636.671,30.901 C635.381,33.114 636.002,36.009 638.194,37.475 C637.388,37.449 636.628,37.228 635.965,36.859 C635.911,39.14 637.546,41.274 639.914,41.749 C639.221,41.937 638.462,41.981 637.69,41.833 C638.316,43.789 640.134,45.212 642.29,45.252 C640.22,46.875 637.612,47.6 635,47.292 C637.179,48.689 639.768,49.504 642.548,49.504 C651.69,49.504 656.855,41.783 656.543,34.858 C657.505,34.163 658.34,33.296 659,32.309 Z"
      transform="translate(-635 -30)"
    />
  </svg>
);

const DribbbleIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <StyledPath
      d="M696,28 C702.628,28 708,33.373 708,40 C708,46.627 702.628,52 696,52 C689.372,52 684,46.627 684,40 C684,33.373 689.372,28 696,28 Z M697.514,41.783 C694.398,42.712 691.885,44.722 689.916,47.81 C692.604,49.909 696.208,50.534 699.557,49.243 C699.123,46.693 698.442,44.203 697.514,41.783 Z M705.781,41.539 C703.522,41.147 701.458,41.078 699.582,41.343 C700.422,43.587 701.064,45.887 701.502,48.24 C703.753,46.731 705.344,44.325 705.781,41.539 Z M696.05,38.422 C693.432588,39.2445882 690.337723,39.685391 686.773742,39.7385724 L686.1,39.744 L686.087,40 C686.087,42.407 686.951,44.615 688.383,46.334 C690.555,43.076 693.34,40.911 696.72,39.854 C696.506,39.374 696.283,38.897 696.05,38.422 Z M703.563,33.606 C702.18,35.33 700.325,36.688 698.015,37.688 C698.285,38.243 698.538,38.805 698.782,39.368 C700.942,38.996 703.31,39.019 705.885,39.441 C705.76,37.225 704.913,35.2 703.563,33.606 Z M691.527,31.166 C688.981,32.459 687.072,34.817 686.38,37.652 C689.733,37.629 692.634,37.262 695.064,36.547 C694.038,34.704 692.858,32.908 691.527,31.166 Z M696,30.087 C695.16,30.087 694.348,30.204 693.569,30.401 C694.879,32.163 696.041,33.978 697.057,35.839 C699.175,34.942 700.838,33.713 702.043,32.159 C700.368,30.866 698.275,30.087 696,30.087 Z"
      transform="translate(-684 -28)"
    />
  </svg>
);

const MailIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <StyledPath
      d="M742,44.668 L745.258,47.569 L742,52 L742,44.668 Z M757,28 L751,50 L742.871,42.761 L750.673,34.527 L740.215,41.754 L733,40 L757,28 Z"
      transform="translate(-733 -28)"
    />
  </svg>
);

const LinkedIcon = () => (
  <svg width="24" height="23" viewBox="0 0 24 23">
    <StyledPath
      d="M787,36 L787,52 L782,52 L782,36 L787,36 Z M794.982,38.155 C797.078,34.276 806,33.989 806,41.869 L806,52 L801.012,52 L801.012,43.601 C801.012,38.549 794.983,38.931 794.983,43.601 L794.983,52 L790.014,52 L790.014,36 L794.982,36 L794.982,38.155 Z M784.5,29 C785.87,29 786.98,30.12 786.98,31.5 C786.98,32.881 785.87,34 784.5,34 C783.13,34 782.02,32.881 782.02,31.5 C782.02,30.12 783.13,29 784.5,29 Z"
      transform="translate(-782 -29)"
    />
  </svg>
);

const StyledPath = styled.path`
  fill: ${({ theme }) => theme.colors.text};
`;

const Icon = styled.li`
  display: inline-block;
  vertical-align: middle;
  list-style: none;
  margin-right: 25px;
  :last-child {
    margin-right: 0;
  }
  a {
    display: block;
  }
  svg {
    vertical-align: top;
  }
`;

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 0;
  background-color: ${({ theme }) => theme.colors.bg};
`;

export default Footer;
