import * as React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Banner: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      userPic: file(relativePath: { eq: "me.jpeg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Wrapper>
      <Pic fixed={data.userPic.childImageSharp.fixed} />
      <Title>
        Hi, I'm <strong>Romain</strong>
      </Title>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 400px;
  background-color: ${({ theme }) => theme.colors.bg};
`;

const Title = styled.h1`
  font-weight: 300;
  font-size: 1.5rem;
  margin: 30px 0 0 0;
`;

const Pic = styled(Img)`
  height: 140px;
  width: 140px;
  border-radius: 50%;
  box-sizing: content-box;
  background-color: ${({ theme }) => theme.colors.bg};
  border: ${({ theme }) => `10px solid ${theme.colors.white}`};
`;

export default Banner;
